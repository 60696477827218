import React, { Component } from 'react';
import PropTypes from 'prop-types';
import config from './config';
import { isSubscriptionEqual, areResourceFiltersEqual } from './utils';
import { MISSING_AUTH, buildFetchOptions, convertToSearchParams } from './common';
export { MISSING_AUTH };

const withSubscriptionsManagement = WrappedComponent => {
  class SubscriptionsManagementHOC extends Component {
    static propTypes = {
      environment: PropTypes.oneOf(['int', 'prd']),
      accessToken: PropTypes.string,
      filter: PropTypes.func,
    };

    static defaultProps = {
      environment: 'int',
    };

    prepRequest = ({ body, method } = {}) => {
      const { accessToken, environment } = this.props;
      const options = buildFetchOptions({ accessToken, body, method });

      const { subscriptionsUrl } = config[environment];
      const baseUrl = `${subscriptionsUrl}/v1/subscriptions`;

      return { options, baseUrl };
    };

    getSubscription = async id => {
      const { baseUrl, options } = this.prepRequest();
      const fullUrl = `${baseUrl}/${id}`;

      const res = await fetch(fullUrl, options);

      if (!res.ok) {
        throw res;
      }

      return res.json();
    };

    getSubscriptions = async ({ resourceId = '', resourceType = '', applyFilter = true }) => {
      const { baseUrl, options } = this.prepRequest();
      const fullUrl = baseUrl + convertToSearchParams({ resourceId, resourceType });

      const res = await fetch(fullUrl, options);
      if (!res.ok) {
        throw res;
      }

      const { subscriptions } = await res.json();
      return applyFilter && this.props.filter ? subscriptions.filter(this.props.filter) : subscriptions;
    };

    postSubscription = async subscription => {
      const { baseUrl, options } = this.prepRequest({ body: subscription });
      const res = await fetch(baseUrl, options);

      if (!res.ok) {
        throw res;
      }

      return res.json();
    };

    deleteSubscription = async id => {
      const { baseUrl, options } = this.prepRequest({ method: 'DELETE' });
      const fullUrl = `${baseUrl}/${id}`;
      const res = await fetch(fullUrl, options);

      if (!res.ok) {
        throw res;
      }
    };

    deleteSubscriptionByResourceType = async resourceType => {
      const { baseUrl, options } = this.prepRequest({ method: 'DELETE' });
      const fullUrl = baseUrl + convertToSearchParams({ resourceType });
      const res = await fetch(fullUrl, options);

      if (!res.ok) {
        throw res;
      }
    };

    createSubscriptionUpdatePlan = ({ subscriptionsBefore = [], subscriptionsAfter = [] }, options) => {
      const subscriptionsToAdd = subscriptionsAfter.filter(
        after => !subscriptionsBefore.some(before => isSubscriptionEqual(before, after, options))
      );
      const subscriptionsToDelete = subscriptionsBefore.filter(
        before => !subscriptionsAfter.some(after => isSubscriptionEqual(before, after, options))
      );
      return { subscriptionsToAdd, subscriptionsToDelete };
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          isSubscriptionEqual={isSubscriptionEqual}
          areResourceFiltersEqual={areResourceFiltersEqual}
          getSubscription={this.getSubscription}
          getSubscriptions={this.getSubscriptions}
          postSubscription={this.postSubscription}
          deleteSubscription={this.deleteSubscription}
          deleteSubscriptionByResourceType={this.deleteSubscriptionByResourceType}
          createSubscriptionUpdatePlan={this.createSubscriptionUpdatePlan}
        />
      );
    }
  }

  return SubscriptionsManagementHOC;
};

export default withSubscriptionsManagement;
