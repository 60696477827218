export default {
  int: {
    authorizationUrl: 'https://int-subscriptions.platform.cimpress.io',
    subscriptionsUrl: 'https://int-subscriptions.commerce.cimpress.io',
  },
  prd: {
    authorizationUrl: 'https://subscriptions.platform.cimpress.io',
    subscriptionsUrl: 'https://subscriptions.commerce.cimpress.io',
  },
};
