import React from 'react';
import PropTypes from 'prop-types';

import IconShoppingCartFull from '@cimpress-technology/react-streamline-icons/lib/IconShoppingCartFull';
import IconShirtPlain from '@cimpress-technology/react-streamline-icons/lib/IconShirtPlain';
import IconDesignToolPenStation from '@cimpress-technology/react-streamline-icons/lib/IconDesignToolPenStation';
import IconShipmentTruck from '@cimpress-technology/react-streamline-icons/lib/IconShipmentTruck';
import IconShop from '@cimpress-technology/react-streamline-icons/lib/IconShop';
import IconAnalyticsPieAlt from '@cimpress-technology/react-streamline-icons/lib/IconAnalyticsPieAlt';
import IconUserSetting from '@cimpress-technology/react-streamline-icons/lib/IconUserSetting';
import IconFolderCode from '@cimpress-technology/react-streamline-icons/lib/IconFolderCode';
import IconBookOpen from '@cimpress-technology/react-streamline-icons/lib/IconBookOpen';
import IconMonitorShoppingCart from '@cimpress-technology/react-streamline-icons/lib/IconMonitorShoppingCart';

const icons = {
  IconShoppingCartFull,
  IconShirtPlain,
  IconDesignToolPenStation,
  IconShipmentTruck,
  IconShop,
  IconAnalyticsPieAlt,
  IconUserSetting,
  IconFolderCode,
  IconBookOpen,
  IconMonitorShoppingCart,
};

const NavItem = ({ item, active, nested, onClick }) => {
  const IconComponent = item.iconComponent ? icons[item.iconComponent] : undefined;
  return (
    <a
      onClick={onClick}
      href={item.url}
      className={`list-group-item clickable${active ? ' active' : ''}${nested ? ' level-two' : ''}`}>
      {IconComponent ? (
        <IconComponent style={{ marginRight: '21px' }} />
      ) : item.glyphicon ? (
        <i className={`fa fa-${item.glyphicon}`} />
      ) : item.iconUrl ? (
        <img src={item.iconUrl} />
      ) : null}
      {item.text}
      {item.subItems && <i className={`fa fa-xl fa-angle-${active ? 'up' : 'down'} pull-right`} />}
    </a>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.bool,
  nested: PropTypes.bool,
  onClick: PropTypes.func,
};

export default NavItem;
