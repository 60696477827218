export const MISSING_AUTH = 'missingAuth';
export const CANCEL = 'cancel';
export const INVALID_TOKEN = 'invalidToken';
export const BACKEND = 'backend';

// https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript
export const parseJwt = token => {
  const [_, base64Url = ''] = token.split('.');
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  return JSON.parse(window.atob(base64));
};

export const buildFetchOptions = ({ method, body, accessToken }) => {
  if (!accessToken) {
    throw { reason: MISSING_AUTH };
  }

  const headers = new Headers({
    Accept: 'application/json',
    Authorization: `Bearer ${accessToken}`,
  });

  method = !method && body ? 'POST' : method ? method.toUpperCase() : 'GET';
  if (method === 'POST' || method === 'PUT' || method === 'PATCH') {
    headers.append('Content-Type', 'application/json');
  }

  const options = {
    method,
    mode: 'cors',
    headers,
  };

  if (body) {
    if (typeof body !== 'string') {
      body = JSON.stringify(body);
    }
    options.body = body;
  }

  return options;
};

export const convertToSearchParams = obj => {
  const params = new URLSearchParams();
  let isEmpty = true;

  Object.entries(obj).forEach(([key, value]) => {
    if (value) {
      isEmpty = false;
      params.set(key, value);
    }
  });

  return isEmpty ? '' : `?${params.toString()}`;
};
