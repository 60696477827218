import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@cimpress/react-components';
import config from './config';
import { MISSING_AUTH, CANCEL, INVALID_TOKEN, BACKEND } from './common';
export { MISSING_AUTH, CANCEL, INVALID_TOKEN, BACKEND };

// https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript
const parseJwt = token => {
  const [_, base64Url = ''] = token.split('.');
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  return JSON.parse(window.atob(base64));
};

export const AUTHORIZATION_RETURN_KEY = 'authorizationReturn';

class AuthorizationModal extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    environment: PropTypes.oneOf(['int', 'prd']).isRequired,
    intl: PropTypes.shape({
      authModalTitle: PropTypes.string.isRequired,
      cancel: PropTypes.string.isRequired,
      next: PropTypes.string.isRequired,
      modalParagraph1: PropTypes.string.isRequired,
      modalParagraph2: PropTypes.string.isRequired,
    }).isRequired,
    show: PropTypes.bool.isRequired,
  };

  onRedirect = () => {
    const { environment } = this.props;
    const { authorizationUrl } = config[environment];
    const returnUrl = new URL(window.location.href);
    returnUrl.searchParams.set(AUTHORIZATION_RETURN_KEY, true);
    window.location.href = `${authorizationUrl}/subscriptions/authorize?returnUri=${encodeURIComponent(
      returnUrl.href
    )}`;
  };

  render() {
    const {
      show,
      onClose,
      intl: { authModalTitle, cancel, next, modalParagraph1, modalParagraph2 },
    } = this.props;
    return (
      <Modal
        show={show}
        onRequestHide={onClose}
        closeButton
        closeOnOutsideClick
        title={authModalTitle}
        footer={
          <Fragment>
            <Button type="default" onClick={onClose}>
              {cancel}
            </Button>
            <Button type="primary" onClick={this.onRedirect}>
              {next}
            </Button>
          </Fragment>
        }>
        <p>{modalParagraph1}</p>
        <p>{modalParagraph2}</p>
      </Modal>
    );
  }
}

const withNotificationsHubAuthFlow = WrappedComponent => {
  class NotificationsHubAuthFlowHOC extends Component {
    static propTypes = {
      environment: PropTypes.oneOf(['int', 'prd']),
      accessToken: PropTypes.string,
      location: PropTypes.shape({
        search: PropTypes.string,
      }),
      history: PropTypes.shape({
        replace: PropTypes.func.isRequired,
      }),
      intl: PropTypes.shape({
        authModalTitle: PropTypes.string.isRequired,
        cancel: PropTypes.string.isRequired,
        next: PropTypes.string.isRequired,
        modalParagraph1: PropTypes.string.isRequired,
        modalParagraph2: PropTypes.string.isRequired,
      }),
    };

    static defaultProps = {
      environment: 'int',
      location: {},
      intl: {
        authModalTitle: 'Agree to Allow MCP to Send You Notifications',
        cancel: 'Cancel',
        next: 'Next',
        modalParagraph1:
          'To successfully subscribe to receive email notifications from MCP, you need to grant us permission to send you messages. You only have to do this once.',
        modalParagraph2:
          'Clicking "Next" will direct you away from this page temporarily so you can agree to allow MCP to send you messages. Afterwards, you will be returned to this page to finish selectng your notification subscriptions.',
      },
    };

    constructor(props) {
      super(props);

      const {
        location: { search = '' },
        history,
      } = this.props;
      const queryParams = new URLSearchParams(search);
      const returningFromAuth = Boolean(queryParams.get(AUTHORIZATION_RETURN_KEY));

      if (returningFromAuth && history) {
        queryParams.delete(AUTHORIZATION_RETURN_KEY);
        history.replace({
          search: queryParams.toString(),
        });
      }

      this.state = {
        showAuthModal: false,
        returningFromAuth,
      };
    }

    componentDidMount() {
      const { accessToken } = this.props;

      if (accessToken) {
        this.loadUser();
      }
    }

    componentDidUpdate(prevProps) {
      if (this.props.accessToken && this.props.accessToken !== prevProps.accessToken) {
        this.loadUser();
      }
    }

    loadUser = () => {
      this.getUserPromise = this.getUser().then(user => {
        const { isAuthorized } = user;
        this.setState({ isAuthorized });
        return user;
      });
    };

    getUser = () => {
      const { accessToken, environment } = this.props;
      if (!accessToken) {
        return Promise.reject({ reason: MISSING_AUTH });
      }

      let decoded;
      try {
        decoded = parseJwt(accessToken);
      } catch (error) {
        return Promise.reject({ reason: INVALID_TOKEN, error });
      }

      const { sub } = decoded;
      if (!sub) {
        return Promise.reject({ reason: INVALID_TOKEN });
      }

      const headers = new Headers({
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
      });

      const options = {
        method: 'GET',
        mode: 'cors',
        headers,
      };

      const { subscriptionsUrl } = config[environment];
      const fullUrl = `${subscriptionsUrl}/v1/users/${sub}`;

      return fetch(fullUrl, options).then(res => {
        if (res.ok) {
          return res.json();
        }

        if (res.status === 404) {
          return { isAuthorized: false };
        }

        return Promise.reject({ reason: BACKEND, error: res });
      });
    };

    ensureAuthorized = () =>
      new Promise((resolve, reject) => {
        this.setState({ reject });

        if (this.getUserPromise) {
          this.getUserPromise
            .then(user => {
              if (!user.isAuthorized) {
                this.setState({ showAuthModal: true });
              } else {
                resolve(user);
              }
            })
            .catch(reject);
        } else {
          reject({ reason: MISSING_AUTH });
        }
      });

    showModal = () => this.setState({ showAuthModal: true });
    closeModal = () => {
      this.state.reject({ reason: CANCEL });
      this.setState({ showAuthModal: false, returningFromAuth: false });
    };
    finishAuthFlow = () => {
      this.setState({ showAuthModal: false, returningFromAuth: false });
    };

    render() {
      const { showAuthModal, isAuthorized, returningFromAuth } = this.state;
      const { environment, intl } = this.props;
      return (
        <Fragment>
          <AuthorizationModal show={showAuthModal} environment={environment} onClose={this.closeModal} intl={intl} />
          <WrappedComponent
            {...this.props}
            finishAuthFlow={this.finishAuthFlow}
            returningFromAuth={returningFromAuth}
            isAuthorized={isAuthorized}
            ensureAuthorized={this.ensureAuthorized}
          />
        </Fragment>
      );
    }
  }

  return NotificationsHubAuthFlowHOC;
};

export default withNotificationsHubAuthFlow;
